import { Typography, Grid,makeStyles } from '@material-ui/core'
import React from 'react'
import Layout from '../components/layout'

const useStyles = makeStyles((theme)=> ({
    root: {
        marginTop: theme.spacing(5),
        marginRight:theme.spacing(2),
        marginLeft:theme.spacing(2),
        gap:20,
        [theme.breakpoints.down('md')]:{
            marginTop: theme.spacing(2),
        }
    },

}))

const KVKK_TR = ({ location }) => {
    const classes = useStyles()

    return (<Layout nodeLocale='tr-TR' location={location}>
        <Grid container className={classes.root}>
            <Typography component='h1' variant='h3'>Kişisel Verilerin Korunması</Typography>
            <Grid item>
                <Typography variant='body1'>
                    Atlaspet Pet Ürünleri Pazarlama ve Ticaret A.Ş.  (“Atlaspet”) olarak kişisel verilerinizin güvenliği hususuna azami hassasiyet göstermekteyiz. Atlaspet olarak ürün ve hizmetlerimizden faydalanan kişiler dahil, Atlaspet ile ilişkili tüm şahıslara ait her türlü kişisel verilerin 6698 sayılı Kişisel Verilerin Korunması Kanunu'na (“KVKK”) uygun olarak işlenerek, muhafaza edilmesine önem vermekteyiz. Bu sorumluluğumuzun tam bilinci ile KVKK uyarınca “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi aşağıda yer alan kapsamda ve şartlarda işlemekteyiz.
                </Typography>
                <Typography variant='body1'>
                    Atlaspet tarafından kişisel verilerinizin ne tür yöntemler aracılığıyla ve hangi amaçlar doğrultusunda işlendiği ve saklandığı hakkında daha detaylı bilgi için Atlaspet Gizlilik İlkeleri ve Çerezler Hakkında Bildirim'i inceleyebilirsiniz.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    Kişisel veri nedir?
                </Typography>
                <Typography variant='body2'>
                    Kişisel veri, KVKK’da kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi olarak tanımlanmıştır. Buna göre bizimle paylaştığınız adınız, soyadınız, doğum tarihiniz, kimlik numaranız, elektronik posta adresiniz, telefon numaranız ve benzeri bilgiler kişisel veri olarak adlandırılmaktadır.
                </Typography>
                <Typography variant='body2'>
                    Atlaspet, kişisel verilerinizi, Atlaspet ile doğrudan paylaştığınız hallerde, otomatik yollarla veya üçüncü şahıs platformları gibi başka kaynaklar aracılığıyla toplamaktadır.
                </Typography>
            </Grid>
           <Grid item>
                <Typography variant='h5'>
                    Kişisel verilerinizin işlenme amacı ve dayanağı nedir?
                </Typography>
                <Typography variant='body2'>
                    Atlaspet ile olan ürün ve hizmet ilişkiniz dolayısıyla yasal yükümlülüklerimizi yerine getirebilmek ve sizi kampanyalar ve avantajlardan haberdar edebilmek için Atlaspet kişisel bilgilerinizi toplamaktadır ve bu kapsamda işlemektedir.
                </Typography>
                <Typography variant='body2'>
                    Bu doğrultuda Atlaspet; kişisel verilerinizi, her türlü şikâyetinizi değerlendirmek ve işleme almak, ödemelerinizi işleme almak, ürünlerimizi, hizmetlerimizi, iletişim yöntemlerimizi ve internet sitelerimizin işlevselliğini geliştirmek ve sizin için kişiselleştirilmiş ürünler, iletişim içerikleri ve hedefe yönelik reklamlar ve ayrıca ürün tavsiyeleri sunmak için toplamakta ve işlemektedir.
                </Typography>
                <Typography variant="body2">
                    Yukarıda sayılan amaçlar doğrultusunda Atlaspet, kişisel verilerinizi, açık rızanıza istinaden, veya sizinle yaptığımız sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması halinde veya temel hak ve özgürlüklerinize zarar vermemek kaydıyla meşru menfaatimizin gerektirdiği hallerde, burada belirtilen amaçlar ve kapsam dahilinde işlemekte ve saklamaktadır.
                </Typography>
           </Grid>
            <Grid item>
                <Typography variant='h5'>
                    Kişisel verilerinizi hangi amaçla, kimlere aktarıyoruz?
                </Typography>
                <Typography variant="body2">
                    Kişisel verileriniz Atlaspet hissedarlarıyla, doğrudan/dolaylı yurtiçi/yurtdışı iştiraklerimizle, iş ilişkisinin devamı esnasında birlikte bizi temsil eden ve/veya faaliyetlerimizi yürütebilmek için işbirliği yaptığımız iş ortağımız olan yurtiçi/yurtdışı kişi ve kurumlarla (kargo, gönderi, çağrı merkezi, veri tabanı, bulut vb. hizmetleri sunan şirketlerle) paylaşılabilmektedir. Ayrıca, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere mahkemeler ve diğer kamu kurumları ile kişisel veriler paylaşılmaktadır. Ayrıca, Atlaspet internet sitelerini ziyaretinize ilişkin kişisel verilerinizi ve gezinme bilgileriniz gibi trafik bilgilerinizi; güvenliğiniz ve Atlaspet'in ilgili mevzuat kapsamındaki yükümlülüklerinin ifası amacıyla yasal olarak bu bilgileri kanunen talep etmeye yetkili olan kamu kurum ve kuruluşları ile paylaşabilecektir.
                </Typography>
            </Grid>
            <Grid>
                <Typography variant='h5'>
                    Kişisel verilerinizi nasıl saklıyoruz?
                </Typography>
                <Typography variant="body2">
                    Şirketimiz ile paylaşılan kişisel verileriniz Atlaspet’in yurtiçi/yurtdışı güvenli sunucularında ilgili yasal düzenlemelere, KVKK hükümlerine ve Atlaspet standartlarına uygun olarak saklanmaktadır. Bu kapsamda Atlaspet, kişisel verilerinizin güvenliğini sağlamak adına yasal mevzuat ile belirlenen gerekli teknik ve idari güvenlik önlemlerini almaktadır.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    Kişisel verilerinizi ne kadar süre ile tutuyoruz?
                </Typography>
                <Typography variant="body2">
                    KVKK Madde 7/f.1. hükmü uyarınca, kişisel verilerinizin işlenmesi gerektiren amaç ortadan kalktığında ve/veya ilgili mevzuat uyarınca verilerinizi saklamakla yükümlü kılındığımız yasal süreler dolduğunda, kişisel verileriniz tarafımızca silinecek, yok edilecek veya anonim hale getirilecektir.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    Kişisel Verilerin Korunması Kanunu’ndan doğan haklarınız nelerdir?
                </Typography>
                <Typography variant="body2">
                    KVKK Madde 11 uyarınca, kişisel verilerinizin işlendiği Atlaspet tarafından veri sorumlusu sıfatı ile işlediği ölçüde;
                </Typography>
                <ul>
                    <li>herhangi bir kişisel verinizin işlenip işlenmediğini öğrenme;</li>
                    <li>kişisel verilerinizin işlenme faaliyetlerine ilişkin olarak bilgi talep etme;</li>
                    <li>kişisel verilerinizin işlenme amaçlarını öğrenme;</li>
                    <li>kişisel verilerin yurt içinde veya yurt dışında üçüncü kişilere aktarılmış olması durumunda bu kişileri öğrenme;</li>
                    <li>kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme;</li>
                    <li>kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini veya yok edilmesini isteme;</li>
                    <li>silme ve düzeltme işlemlerinin, verilerin aktarıldığını üçüncü kişilere bildirilmesi isteme;</li>
                    <li>kişisel verilerin otomatik sistemler vasıtasıyla işlenmesi sonucu ortaya çıkabilecek aleyhte sonuçlara itiraz etme; ve
                        kişisel verilerinizin kanuna aykırı bir şekilde işlenmesi sebebiyle zarara uğramanız halinde bu zararın tazmin edilmesini isteme hakkına sahipsiniz.</li>
                </ul>
                <Typography variant='body2'>başvurunuzda yer alan talepleriniz, talebin niteliğine göre en kısa sürede ve en geç otuz gün içinde sonuçlandırılacaktır.
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    Tarafımıza bildirmiş olduğunuz kişisel verilerinize ilişkin haklarınızı ne şekilde kullanabilirsiniz?
                </Typography>
                <Typography variant="body2">
                    KVKK Madde 11'de yer alan ve yukarıda sayılan haklarınızı https://www.Atlaspet.com.tr/iletisim adresindeki iletişim formu aracılığıyla veya Atlaspet tüketici hattı 0212-807 01 40’u arayarak kullanabilirsiniz.
                </Typography>
            </Grid>


        </Grid>
    </Layout>)
}
export default KVKK_TR